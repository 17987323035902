<template>
  <div>
    {{ $t(changeDocumentOrMeteoType) }}
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    changeDocumentOrMeteoType() {
      return this.type.split(', ')[1];
    }
  }
};
</script>
